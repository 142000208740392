import { useCallback } from 'react';

export function useSolveQuestionWithAi() {
  const isListEligible = useCallback(list => {
    const isListFromMKT = list.status === 'incomplete';
    if (isListFromMKT) return true;
    if (!list.discipline) return false;

    return list.discipline.themeId === 1 || list.discipline.themeId === 2; // Calculo (1) e Fisica (2)
  }, []);

  const isQuestionEligible = useCallback(question => {
    if (question.images.length > 0) return false;
    if (question.status === 'found' && question.exerciseType !== 'MvpExercise')
      return false;

    return true;
  }, []);

  const isAllEligible = useCallback(
    (list, question) => {
      const isEligibleToAi =
        isListEligible(list) && isQuestionEligible(question);

      return isEligibleToAi;
    },
    [isListEligible, isQuestionEligible]
  );

  return { isListEligible, isQuestionEligible, isAllEligible };
}
