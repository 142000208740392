import React, { useCallback, useMemo, useRef } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import { media } from 'styles';
import { useTracker } from 'modules/shared/hooks';

export default function BookTab({
  onChangeTab,
  currentTab,
  edition,
  primaryColor,
}) {
  const sliderRef = useRef(null);
  const { trackEvent } = useTracker();

  const tabs = [
    { option: 0, label: ['Resolução', 'passo a passo'] },
    {
      option: 1,
      label: ['Teoria em', 'texto ou vídeo'],
      eventName: 'topic',
    },
    {
      option: 2,
      label: ['Mais questões', 'parecidas'],
      eventName: 'more-exercises',
    },
    {
      option: 3,
      label: ['Aulão desse', 'assunto'],
      eventName: 'Aulão',
    },
  ];

  const sliderSettings = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
          centerMode: false,
        },
      },
    ],
  };

  const trackClickEvent = useCallback(
    tab => {
      const eventProperties = {
        Destino: tab?.eventName,
        Livro: edition?.amplitudeName,
        Matéria: edition?.book?.theme?.name,
      };
      trackEvent('Solucionário: Clicou em redirecionar', eventProperties);
    },
    [trackEvent, edition]
  );

  return (
    <Container>
      <Slider ref={sliderRef} {...sliderSettings}>
        {tabs.map(tab => (
          <TabItem
            key={tab.option}
            current={tab.option === currentTab}
            primaryColor={primaryColor}
            onClick={() => {
              const isRedirectTab = tab.option !== 0;
              if (isRedirectTab) trackClickEvent(tab);
              onChangeTab(tab);
            }}
          >
            {tab.label.map((l, i) => (
              <React.Fragment key={i}>
                {i !== 0 && <br />}
                {l}
              </React.Fragment>
            ))}
          </TabItem>
        ))}
      </Slider>
    </Container>
  );
}

const TabItem = styled.div`
  text-align: center;
  border-bottom: 2px solid;
  cursor: pointer;
  padding: 0 40px 2px 40px;
  color: ${({ current, primaryColor }) => (current ? primaryColor : '#b4b4b4')};
  text-transform: uppercase;
  font-size: 0.95em;
  font-family: Lato;

  @media ${media.desktop} {
    &:hover {
      color: ${({ primaryColor }) => primaryColor};
    }
  }

  @media ${media.mobile} {
    width: 250px;
    height: 50px;
    padding: 0 20px 2px 20px;
    display: flex !important;
    justify-content: center;
    align-items: flex-end;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  margin: 30px 0 40px 0;
  padding: 0 30px;
  padding: 0 50px;

  .slick-track {
    display: flex;
  }

  .slick-slider {
    width: 100%;
  }

  .slick-slide {
    margin: 0 1px;
  }

  @media ${media.mobile} {
    padding: 0;
    margin: 0 0 30px 0;

    .slick-list {
      padding: 0 30% 0 0 !important;
    }
  }
`;
