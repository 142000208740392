import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useUserContext } from './UserProvider';

export const TooltipContext = createContext(null);

const TooltipProvider = props => {
  const { user } = useUserContext();

  const [isShowing, setIsShowing] = useState(true);
  const [previewPosition, setPreviewPosition] = useState({ x: 0, y: 0 });
  const [activeListId, setActiveListId] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      setIsShowing(false);
      setActiveListId(null);
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const stopPreview = useCallback(() => {
    setIsShowing(false);
    setActiveListId(null);
  }, []);

  const calculePreviewPosition = useCallback(
    (target, previewHeight, previewWidth) => {
      const xPosition =
        target.getBoundingClientRect().left -
        previewWidth / 2 +
        target.offsetWidth / 2;

      let yPosition;
      if (target.getBoundingClientRect().top < previewHeight + 5) {
        // render under component
        yPosition =
          target.getBoundingClientRect().top + target.offsetHeight + 5;
      } else {
        yPosition = target.getBoundingClientRect().top - previewHeight - 5;
      }

      return {
        x: xPosition,
        y: yPosition,
      };
    },
    []
  );

  const showPreview = useCallback(
    (target, previewHeight, previewWidth, listId) => {
      setPreviewPosition(
        calculePreviewPosition(target, previewHeight, previewWidth)
      );

      setIsShowing(true);
      setActiveListId(listId);
    },
    [calculePreviewPosition]
  );

  return (
    <TooltipContext.Provider
      value={{
        isShowing,
        previewPosition,
        showPreview,
        stopPreview,
        activeListId,
      }}
      {...props}
    />
  );
};

export const useTooltipContext = () => useContext(TooltipContext);

export default TooltipProvider;
