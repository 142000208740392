import { useTestAB, useTracker, useUTM } from 'modules/shared/hooks';
import { useCallback, useMemo } from 'react';
import { media } from 'styles';

export function useTrackEvents() {
  const { getUTMsByLocation } = useUTM();
  const { trackEvent, amplitudeStartSessionReply } = useTracker();
  const { getVariantFromTestAB } = useTestAB();

  const utms = useMemo(() => {
    return getUTMsByLocation(window.location);
  }, [getUTMsByLocation]);

  const defaultProperties = useMemo(() => {
    return {
      ...utms,
      Versão: 1,
      Plataforma: media.isMobile() ? 'Mobile' : 'Desktop',
      'Versão Teste':
        getVariantFromTestAB('statement_list') === 'trial_2_questions'
          ? 'Trial 2 Questões'
          : 'Trial Cadeado',
    };
  }, [getVariantFromTestAB, utms]);

  const getListProps = useCallback(list => {
    return {
      'ID da Lista': list.id,
      Professor: list.professor?.name,
      Disciplina: list.discipline?.name,
      Exame: list?.exam,
      'Quantidade Questoes': list.questions?.length,
      'Status da Lista': list.status,
    };
  }, []);

  const trackViewListPage = useCallback(
    (list, solvedPercentage) => {
      trackEvent('Lista de Enunciado: Visualizou página de lista', {
        ...getListProps(list),
        '% Resolvida': solvedPercentage,
        ...defaultProperties,
      });
    },
    [trackEvent, getListProps, defaultProperties]
  );

  const recordScreen = useCallback(() => {
    if (utms['MKT: UTM Source'] === 'whatsapp-bot') {
      amplitudeStartSessionReply(0.5);
    }
  }, [utms, amplitudeStartSessionReply]);

  const getQuestionProps = useCallback(question => {
    return {
      'ID da Questao': question.id,
      'Label da Questao': question.label,
      'Tipo Exercicio': question.exerciseType,
      'ID Exercicio': question.exerciseId,
      'Exercicio Feito por IA?':
        question.exerciseType === 'MvpExercise' ? 'Sim' : 'Não',
    };
  }, []);

  const trackViewCheckSearchResult = useCallback(
    (question, list) => {
      trackEvent('Lista de Enunciado: Visualizou tela de validar resultado', {
        ...getQuestionProps(question),
        ...getListProps(list),
        ...defaultProperties,
      });
    },
    [trackEvent, getQuestionProps, getListProps, defaultProperties]
  );

  const trackCheckResultSearch = useCallback(
    (question, found, list) => {
      trackEvent('Lista de Enunciado: Validou resultado', {
        ...getQuestionProps(question),
        ...getListProps(list),
        'Questao Certa?': found ? 'Sim' : 'Nao',
        ...defaultProperties,
      });
    },
    [trackEvent, getQuestionProps, getListProps, defaultProperties]
  );

  const trackViewQuestion = useCallback(
    (question, list) => {
      trackEvent('Lista de Enunciado: Visualizou pagina da resolução', {
        ...getQuestionProps(question),
        ...getListProps(list),
        ...defaultProperties,
      });
    },
    [trackEvent, getQuestionProps, getListProps, defaultProperties]
  );

  const trackViewSearchOptions = useCallback(
    (question, list) => {
      trackEvent('Lista de Enunciado: Visualizou possibilidades da busca', {
        ...getQuestionProps(question),
        ...getListProps(list),
        ...defaultProperties,
      });
    },
    [trackEvent, getQuestionProps, getListProps, defaultProperties]
  );

  const trackSelectSearchOptions = useCallback(
    (question, list, selectedLog) => {
      trackEvent('Lista de Enunciado: Selecionou possibilidade da busca', {
        ...getQuestionProps(question),
        ...getListProps(list),
        ...defaultProperties,
        'Tipo Exercicio': selectedLog.exerciseType,
        'ID Exercicio': selectedLog.exerciseId,
      });
    },
    [trackEvent, getQuestionProps, getListProps, defaultProperties]
  );

  const trackClickFavoriteFeature = useCallback(
    ({ isFavorite, listId, origin }) => {
      trackEvent('Lista de Enunciado: Clicou Favoritar', {
        'ID da Lista': listId,
        ...defaultProperties,
        Favorito: isFavorite ? 'Sim' : 'Nao',
        Local: origin,
      });
    },
    [trackEvent, defaultProperties]
  );

  const trackGenerateAiSolution = useCallback(
    (question, list) => {
      trackEvent('Lista de Enunciado: Gerou resolução com IA', {
        ...getQuestionProps(question),
        ...getListProps(list),
        ...defaultProperties,
      });
    },
    [trackEvent, getQuestionProps, getListProps, defaultProperties]
  );

  const trackViewZeroPercentDialog = useCallback(
    list => {
      trackEvent('Lista de Enunciado: Visualizou dialog 0%', {
        ...getListProps(list),
        ...defaultProperties,
      });
    },
    [trackEvent, getListProps, defaultProperties]
  );

  const trackGoBackToSolveList = useCallback(
    list => {
      trackEvent('Lista de Enunciado: Clicou Voltar LP', {
        ...getListProps(list),
        ...defaultProperties,
      });
    },
    [trackEvent, getListProps, defaultProperties]
  );

  const trackClickSimilarQuestions = useCallback(
    list => {
      trackEvent('Lista de Enunciado: Clicou Ver questões similares', {
        ...getListProps(list),
        ...defaultProperties,
      });
    },
    [trackEvent, getListProps, defaultProperties]
  );

  const trackViewLimitQuestionsDialog = useCallback(
    listId => {
      trackEvent('Lista de Enunciado: Visualizou dialog limite de questões', {
        'ID da Lista': listId,
        ...defaultProperties,
      });
    },
    [trackEvent, defaultProperties]
  );

  const trackClickCTALimitQuestionsDialog = useCallback(
    listId => {
      trackEvent('Lista de Enunciado: Clicou CTA dialog limite de questões', {
        'ID da Lista': listId,
        ...defaultProperties,
      });
    },
    [trackEvent, defaultProperties]
  );

  const trackClickToSeeSolutionButton = useCallback(
    (list, question) => {
      trackEvent('Lista de Enunciado: Clicou Botão Ver resolução', {
        ...getListProps(list),
        ...getQuestionProps(question),
        ...defaultProperties,
      });
    },
    [trackEvent, getListProps, getQuestionProps, defaultProperties]
  );

  return {
    trackViewListPage,
    trackViewCheckSearchResult,
    trackCheckResultSearch,
    trackViewQuestion,
    trackViewSearchOptions,
    trackSelectSearchOptions,
    trackClickFavoriteFeature,
    trackGenerateAiSolution,
    recordScreen,
    trackViewZeroPercentDialog,
    trackGoBackToSolveList,
    trackClickSimilarQuestions,
    trackViewLimitQuestionsDialog,
    trackClickCTALimitQuestionsDialog,
    trackClickToSeeSolutionButton,
  };
}
