import React from 'react';
import { Link } from 'react-router-dom';

import { media, styled } from 'styles';

export function QuestionTopBar({ topic, listId, style = {} }) {
  return (
    <Container style={style}>
      <BackLink
        to={`/lista-de-enunciado/${listId}?utm_source=site_navegacao&utm_medium=botao_voltar&utm_content=exercicio_lista`}
      >
        Voltar à lista
      </BackLink>
      {topic && (
        <ReviewTheory>
          Ainda ficou com dúvida?{' '}
          <a
            href={`/aprender/topico/${topic.subjectId}/${topic.id}/teoria/${topic.theory?.id}`}
            target="_blank"
            onClick={e => {
              e.stopPropagation();
            }}
          >
            Revisar matéria
          </a>
        </ReviewTheory>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media ${media.mobile} {
    flex-direction: column;
  }
`;

const BackLink = styled(Link)`
  color: #fff;
  background-color: #fbc531;
  border-radius: 40px;
  padding: 10px 20px;
  cursor: pointer;

  @media ${media.mobile} {
    margin-bottom: 10px;
    width: fit-content;
  }
`;

const ReviewTheory = styled.p`
  display: flex;
  align-items: center;
  color: #5d5d5d;

  a {
    margin-left: 15px;
    color: #fff;
    font-weight: bold;
    background-color: #70c0c7;
    padding: 10px 20px;
    border-radius: 40px;
    cursor: pointer;
  }
`;
