import React, { useCallback, useMemo } from 'react';
import {
  SolvedBooks,
  ChangePlan,
  Covid19,
  UniversityPersonalizedContentBanner,
} from './banners/index.js';
import { DesktopBannerMaker } from '../shared/banners';
import { styled, media } from 'styles';
import ToggleableComponent from 'modules/shared/components/ToggleableComponent';
import { PaymentFailedRecoverBanner } from 'modules/campaigns/recover-subscriber/payment-failed-recover/banners';
import { FormerSubscriberRecoverBanner } from 'modules/campaigns/recover-subscriber/former-subscriber-recover/banners';
import { PerpetualPlansBanner } from 'modules/campaigns/perpetual-plans/banners';
import { useUserContext } from 'providers/UserProvider';
import { useBannerContext } from 'providers/BannerProvider';
import { useMvpBooksEligible } from 'modules/campaigns/mvp-books/hooks/useEligible.js';
import MvpThemesListBanner from 'modules/campaigns/mvp-books/banners/MvpThemesListBanner.js';
import { useCookies } from 'modules/shared/hooks/useCookies.js';

export default function DesktopFrontBanner() {
  const { user } = useUserContext();
  const { bannersToShowInfo } = useBannerContext();
  const { isBannerEligibleInHomePage } = useMvpBooksEligible();
  const [cookies] = useCookies(['examBanner']);

  const shouldShowExamBanner = useCallback(() => {
    const bannerName = bannersToShowInfo?.desktopFrontBannerName;
    if (!bannerName) return false;
    if (
      bannerName === 'desktopBannerMaker' &&
      parseInt(cookies?.examBanner) >= 4 &&
      isBannerEligibleInHomePage()
    ) {
      return false;
    }
    return bannerName === 'desktopBannerMaker';
  }, [bannersToShowInfo?.desktopFrontBannerName, isBannerEligibleInHomePage]);

  const getPriorityBanner = useCallback(() => {
    if (
      bannersToShowInfo?.desktopFrontBannerName === 'paymentFailedRecoverBanner'
    )
      return <PaymentFailedRecoverBanner />;
    if (
      bannersToShowInfo?.desktopFrontBannerName ===
      'formerSubscriberRecoverBanner'
    )
      return <FormerSubscriberRecoverBanner />;
    if (bannersToShowInfo?.desktopFrontBannerName === 'changePlan')
      return <ChangePlan height="130px" />;
    if (isBannerEligibleInHomePage() && shouldShowExamBanner())
      return <DesktopBannerMaker source="Home" />;
    if (
      !isBannerEligibleInHomePage() &&
      bannersToShowInfo?.desktopFrontBannerName === 'desktopBannerMaker'
    )
      return <DesktopBannerMaker source="Home" />;
    if (bannersToShowInfo && isBannerEligibleInHomePage())
      return <MvpThemesListBanner origin={'Matérias'} utmContent="pag_home" />;
    if (bannersToShowInfo?.desktopFrontBannerName === 'perpetualPlans')
      return <PerpetualPlansBanner />;
    if (bannersToShowInfo?.desktopFrontBannerName === 'personalizedContent')
      return <UniversityPersonalizedContentBanner />;
    if (bannersToShowInfo?.desktopFrontBannerName === 'covid19')
      return <Covid19 height="160px" />;
    if (bannersToShowInfo?.desktopFrontBannerName === 'solvedBooks')
      return <SolvedBooks />;
    return null;
  }, [bannersToShowInfo, isBannerEligibleInHomePage, shouldShowExamBanner]);

  const banner = useMemo(() => {
    return getPriorityBanner();
  }, [getPriorityBanner]);

  const canShowDesktopFrontBanner = useMemo(() => {
    return user && banner && media.isDesktop();
  }, [user, banner]);

  if (!canShowDesktopFrontBanner) return null;

  return (
    <ToggleableComponent
      renderDelay={1500}
      open={true}
      height={banner.props.height || '120px'}
    >
      <Container height={banner.props.height} id="desktop-front-banner">
        {banner}
      </Container>
    </ToggleableComponent>
  );
}

const Container = styled.div`
  width: 100%;
  height: ${({ height }) => (height ? height : '120px')};
  cursor: pointer;
`;
