import React from 'react';

import { styled } from 'styles';
import { ExerciseFeedback } from '../shared';
import { MathpixMarkdownContent } from 'modules/shared/components/generic';

export function StepsAndAnswer({ question }) {
  const steps = question.exercise.lightSolution;
  const answer = question.exercise.lightAnswer;

  return (
    <>
      {steps.map((step, index) => (
        <div key={index}>
          <StepTitle>Passo {index + 1}:</StepTitle>
          {step && <LatexContent text={step} />}
        </div>
      ))}

      <ExerciseFeedback
        question={{ ...question, exerciseType: 'MvpExercise' }}
        origin="fim_da_resolucao"
      />

      {answer && (
        <>
          <StepTitle>Resposta:</StepTitle>
          <LatexContent text={answer} />
        </>
      )}
    </>
  );
}

const StepTitle = styled.h1`
  color: #6a0d91;
  font-weight: bold;
  font-size: 1.4em;
  text-decoration: underline;
  text-align: left;
`;

const LatexContent = styled(MathpixMarkdownContent)`
  color: #5d5d5d;
  margin-top: 15px;
  max-width: 100%;
`;
