import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useMvpBooksTracker } from '../hooks/useMVpBooksTracker';
import { useToast } from 'modules/shared/hooks';
import { EXAM_NAMES, themesIdsToShow } from '../data';
import { colors, media } from 'styles';
import { MenuItem, TextField } from '@material-ui/core';
import { Autocomplete } from '@mui/material';
import { matchSorter } from 'match-sorter';
import { useUserContext } from 'providers/UserProvider';
import { useUploadDialogContext } from '../providers/UploadDialogProvider';
import { Loading } from 'modules/shared/components/generic';

export const ListInfoStep = ({ origin }) => {
  const { errorToast } = useToast();
  const {
    trackViewFormScreen,
    trackExamSelected,
    trackDisciplineSelected,
    trackThemeSelected,
    trackProfessorSelected,
  } = useMvpBooksTracker();
  const { user } = useUserContext();
  const {
    professors,
    setProfessor,
    setStep,
    exam,
    setExam,
    statementList,
    themes,
    selectedTheme,
    setSelectedTheme,
    setSelectedDiscipline,
    selectedDiscipline,
    professor,
  } = useUploadDialogContext();

  const [selectedProfessor, setSelectedProfessor] = useState(professor || null);

  const themesFiltered = useMemo(() => {
    if (!themes) return [];

    if (statementList) {
      const themesWithSomeDiscipline = themes.filter(theme => {
        return theme.disciplines.length > 0 || theme.mvpDisciplines.length > 0;
      });
      return themesWithSomeDiscipline;
    } else {
      const specificThemes = themes
        .filter(theme => themesIdsToShow.includes(theme.id))
        .filter(theme => theme.books.length > 0);
      return specificThemes;
    }
  }, [statementList, themes]);

  const disciplines = useMemo(() => {
    if (!selectedTheme) return [];

    return [...selectedTheme.disciplines, ...selectedTheme.mvpDisciplines];
  }, [selectedTheme]);

  const goToNextStep = useCallback(() => {
    if (!selectedTheme || !selectedDiscipline || !selectedProfessor || !exam) {
      errorToast('Preencha todos os campos corretamente');
      return;
    }
    setProfessor(selectedProfessor);

    setStep(3);
  }, [
    selectedTheme,
    selectedDiscipline,
    selectedProfessor,
    exam,
    setProfessor,
    setStep,
    errorToast,
  ]);

  const onThemeChange = useCallback(
    theme => {
      trackThemeSelected({ theme: theme.name });
      setSelectedTheme(theme);
    },
    [setSelectedTheme, trackThemeSelected]
  );

  const onDisciplineChange = useCallback(
    discipline => {
      trackDisciplineSelected({
        theme: selectedTheme.name,
        disciplineName: discipline.name,
        disciplineId: discipline.id,
        isDefault: discipline.modelName === 'MvpDiscipline' ? 'Sim' : 'Não',
      });
      setSelectedDiscipline(discipline);
    },
    [selectedTheme, setSelectedDiscipline, trackDisciplineSelected]
  );

  const onProfessorChange = useCallback(
    professor => {
      trackProfessorSelected({
        theme: selectedTheme.name,
        disciplineName: selectedDiscipline.name,
        disciplineId: selectedDiscipline.id,
        newProfessor: professor.id ? 'Não' : 'Sim',
        professorName: professor.name,
        professorId: professor.id,
      });
    },
    [selectedTheme, selectedDiscipline, trackProfessorSelected]
  );

  const onExamChange = useCallback(
    exam => {
      trackExamSelected({
        theme: selectedTheme?.name,
        disciplineName: selectedDiscipline?.name,
        disciplineId: selectedDiscipline?.id,
        professorName: selectedProfessor?.name,
        professorId: selectedProfessor?.id,
        exam: exam,
      });
      setExam(exam);
    },
    [
      selectedDiscipline,
      selectedProfessor,
      selectedTheme,
      setExam,
      trackExamSelected,
    ]
  );

  const professorSearchFilterOption = (options, { inputValue }) =>
    matchSorter(options, inputValue, {
      keys: ['name'],
      threshold: matchSorter.rankings.WORD_STARTS_WITH,
    });

  const professorsByDiscipline = useMemo(() => {
    if (!selectedDiscipline) return [];

    const filteredProfessorsByDiscipline = professors.filter(professor => {
      return (
        selectedDiscipline.modelName === professor.teachable.modelName &&
        selectedDiscipline.id === professor.teachable.id
      );
    });
    const filteredProfessorsByCampus = filteredProfessorsByDiscipline.filter(
      professor => {
        return professor.universityCampusId == user.universityCampus?.id;
      }
    );
    return filteredProfessorsByCampus;
  }, [selectedDiscipline, professors, user.universityCampus?.id]);

  useEffect(() => {
    const type = statementList ? 'Enunciado' : 'Seção';
    trackViewFormScreen(origin, type);
  }, [trackViewFormScreen, origin, statementList]);

  if (!themes || !professors) return <Loading />;

  return (
    <Container>
      <Subtitle>
        RESOLVEMOS SUA LISTA
        <br /> EM 45 SEGUNDOS!
      </Subtitle>
      <FormContainer>
        <CustomTextField
          label="Selecione sua matéria"
          value={selectedTheme ? selectedTheme : ''}
          onChange={e => {
            onThemeChange(e.target.value);
          }}
          className="select"
          select
          required
        >
          {themesFiltered.map(option => (
            <MenuItem key={option.id} value={option}>
              {option.name}
            </MenuItem>
          ))}
        </CustomTextField>
        <CustomTextField
          label="Selecione sua disciplina"
          value={selectedDiscipline || ''}
          onChange={e => {
            onDisciplineChange(e.target.value);
          }}
          className="select"
          select
          required
          disabled={!selectedTheme}
        >
          {disciplines.map(option => (
            <MenuItem key={option.id} value={option}>
              {option.name}
            </MenuItem>
          ))}
        </CustomTextField>

        <CustomAutoComplete
          id="size-small-standard"
          size="small"
          freeSolo
          options={professorsByDiscipline}
          value={selectedProfessor}
          filterOptions={(options, params) => {
            const filtered = professorSearchFilterOption(options, params);

            if (filtered.length === 0 && params.inputValue !== '') {
              filtered.push({
                id: null,
                name: params.inputValue,
                customLabel: `${params.inputValue} (Criar Professor)`,
              });
            }

            return filtered;
          }}
          onChange={(event, newValue) => {
            if (!newValue) {
              setSelectedProfessor(null);
              return;
            }
            if (typeof newValue === 'string') {
              // if user press enter, newValue will be a string
              setSelectedProfessor({
                id: null,
                name: newValue,
              });
              return;
            }

            if (!newValue.id) {
              // removing customLabel if selected
              onProfessorChange({ id: null, name: newValue.name });

              setSelectedProfessor({
                id: null,
                name: newValue.name,
              });
            } else {
              onProfessorChange({ id: newValue.id, name: newValue.name });
              setSelectedProfessor(newValue);
            }
          }}
          getOptionLabel={option => {
            if (!option.id) return option.customLabel || option.name;

            return option.name;
          }}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label="Selecione ou digite o seu professor"
              placeholder="Digite"
            />
          )}
          clearOnBlur
          selectOnFocus
          autoHighlight
          disabled={!selectedDiscipline}
        />

        <CustomTextField
          label="Essa lista será cobrada em qual Prova?"
          value={exam}
          className="select"
          onChange={e => onExamChange(e.target.value)}
          select
          required
        >
          {EXAM_NAMES.map((examName, i) => (
            <MenuItem key={i} value={examName}>
              {examName}
            </MenuItem>
          ))}
        </CustomTextField>
      </FormContainer>
      <NextButton onClick={goToNextStep}>Continuar</NextButton>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 20px 15px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  width: 90%;
  padding: 0 20px;

  @media ${media.mobile} {
    padding: 0;
  }
`;

const CustomTextField = styled(TextField)`
  margin: 5px 0 !important;
  width: 320px;
  max-width: 100%;
  font-weight: bold;

  &.select {
    .MuiInput-formControl {
      text-align: left;
    }
  }
`;

const CustomAutoComplete = styled(Autocomplete)`
  width: 320px;
  max-width: 100%;
`;

export const NextButton = styled.button`
  width: 390px;
  max-width: 70%;
  background-color: ${colors.mainOrange};
  border-color: ${colors.mainOrange};
  color: white;
  margin-top: 3rem;
  margin-bottom: 1rem;
  padding: 12px 0px;
  border-radius: 30px;
  font-size: 1.1rem;
  font-weight: bold;

  @media ${media.mobile} {
    width: 96%;
    font-size: 0.9rem;
  }
`;

const Subtitle = styled.span`
  color: black;
  font-size: 28px;
  font-weight: bold;
  margin-top: 15px;
`;
