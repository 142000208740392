import React, { createContext, useContext, useState, useEffect } from 'react';

const SCRIPT_ID = 'mathpix-markdown-it-script';

const MathpixContext = createContext(null);

const MathpixProvider = props => {
  const [isMathpixLoaded, setIsMathpixLoaded] = useState(false);

  useEffect(() => {
    const existingScript = document.getElementById(SCRIPT_ID);

    if (!existingScript) {
      let script = document.createElement('script');
      script.id = SCRIPT_ID;
      script.src =
        'https://cdn.jsdelivr.net/npm/mathpix-markdown-it@2.0.6/es5/bundle.js';
      document.head.append(script);
      script.onload = function() {
        const isLoaded = window.loadMathJax();
        if (isLoaded) {
          setIsMathpixLoaded(true);
        }
      };
    } else {
      if (window.MathJax) {
        setIsMathpixLoaded(true);
      } else {
        existingScript.onload = () => {
          if (window.MathJax) {
            setIsMathpixLoaded(true);
          }
        };
      }
    }

    return () => {
      const script = document.getElementById(SCRIPT_ID);

      if (script) {
        document.head.removeChild(script);
      }
      setIsMathpixLoaded(false);
      delete window.MathJax;
    };
  }, []);

  return <MathpixContext.Provider value={{ isMathpixLoaded }} {...props} />;
};

export const useMathpixContext = () => useContext(MathpixContext);

export default MathpixProvider;
