import React, { useCallback, useMemo } from 'react';
import { ToggleableComponent } from 'modules/shared/components/generic';
import UniversityPersonalizedContentBanner from './banners/UniversityPersonalizedContentBanner';
import { DesktopBannerMaker } from '../shared/banners';
import { useBannerContext } from 'providers/BannerProvider';
import styled from 'styled-components';
import { useUserContext } from 'providers/UserProvider';
import { useBookEditionContext } from 'modules/books/providers/BookEditionProvider';
import { useMvpBooksEligible } from 'modules/campaigns/mvp-books/hooks/useEligible';
import MvpBookExerciseListsBanner from 'modules/campaigns/mvp-books/banners/MvpBookExerciseListsBanner';
import { useCookies } from 'modules/shared/hooks';

export default function DesktopBookBanner() {
  const { user } = useUserContext();
  const { bannersToShowInfo } = useBannerContext();
  const { loadingProfessors, professors } = useBookEditionContext();
  const {
    isBannerEligibleInBookProfessorsListsSection,
  } = useMvpBooksEligible();

  const [cookies] = useCookies(['examBanner']);

  const shouldShowMvpListBanner = useMemo(() => {
    return (
      professors &&
      professors.length > 0 &&
      isBannerEligibleInBookProfessorsListsSection()
    );
  }, [isBannerEligibleInBookProfessorsListsSection, professors]);

  const shouldShowExamBanner = useCallback(() => {
    const bannerName = bannersToShowInfo?.desktopFrontBannerName;
    if (!bannerName) return false;
    if (
      bannerName === 'desktopBannerMaker' &&
      parseInt(cookies?.examBanner) >= 5 &&
      shouldShowMvpListBanner
    ) {
      return false;
    }
    return bannerName === 'desktopBannerMaker';
  }, [bannersToShowInfo?.desktopFrontBannerName, shouldShowMvpListBanner]);

  const getHighPriorityFrontBanner = useCallback(() => {
    if (!bannersToShowInfo) return null;
    if (shouldShowExamBanner())
      return <CustomDesktopBannerMaker source="Book" />;
    if (
      bannersToShowInfo?.desktopFrontBannerName === 'desktopBannerMaker' &&
      !shouldShowMvpListBanner
    )
      return <CustomDesktopBannerMaker source="Book" />;
    if (loadingProfessors) return null;
    if (shouldShowMvpListBanner)
      return (
        <MvpBookExerciseListsBanner
          origin="Exercicios de Livro"
          style={{ marginLeft: '35px', marginTop: '0px' }}
        />
      );
    if (bannersToShowInfo?.bookBannerName === 'personalizedContent')
      return <UniversityPersonalizedContentBanner />;
  }, [
    bannersToShowInfo,
    loadingProfessors,
    shouldShowExamBanner,
    shouldShowMvpListBanner,
  ]);

  const isToggleableBanner = useCallback(() => {
    if (user?.subscriber) {
      return false;
    }
    return (
      user &&
      bannersToShowInfo?.workspaceBannerName !== 'linkedinBanner' &&
      bannersToShowInfo?.workspaceBannerName !== 'plannerBanner'
    );
  }, [bannersToShowInfo, user]);

  const banner = useMemo(() => {
    return getHighPriorityFrontBanner();
  }, [getHighPriorityFrontBanner]);

  if (!banner) return null;

  return isToggleableBanner() ? (
    <ToggleableComponent
      renderDelay={1500}
      open={true}
      height={banner.props.height || '120px'}
      data-cy="book-banner"
    >
      {banner}
    </ToggleableComponent>
  ) : (
    <Container height={banner.props.height}>{banner}</Container>
  );
}

const CustomDesktopBannerMaker = styled(DesktopBannerMaker)`
  border-radius: 10px;
  height: 120px;
  width: 98%;
  margin: auto;
`;

const Container = styled.div`
  width: 100%;
  height: ${({ height }) => (height ? height : '120px')};
  cursor: pointer;
`;
