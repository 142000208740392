import { useBookEditionContext } from 'modules/books/providers/BookEditionProvider';
import MathjaxContentWithLatex from 'modules/shared/components/generic/MathjaxContentWithLatex';
import React, { useMemo } from 'react';
import { styled } from 'styles';

export default function Answer({ blur, primaryColor, solvedByAi }) {
  const { bookExercise } = useBookEditionContext();

  const fakeAnswer = useMemo(() => {
    if (!bookExercise?.lightSolution) return '';

    return bookExercise.lightSolution[0];
  }, [bookExercise]);

  if (!bookExercise) return null;
  return (
    <div>
      <StepHeader primaryColor={primaryColor}>Resposta</StepHeader>
      <AnswerContainer className={blur && 'blurs'} primaryColor={primaryColor}>
        <MathjaxContentWithLatex
          text={blur ? fakeAnswer : bookExercise?.lightAnswer}
          dataCy="book-answer"
          isLatex={!!solvedByAi}
        />
      </AnswerContainer>
    </div>
  );
}

const AnswerContainer = styled.div`
  border-left: 4px solid ${props => props.primaryColor};
  padding: 20px;
  font-size: 1.2em;

  p,
  li {
    color: #888;
    margin-bottom: 15px;
    text-align: left;
    overflow-x: auto;
    overflow-y: hidden;
  }
`;

const StepHeader = styled.h2`
  color: ${props => props.primaryColor};
  font-size: 1.5em;
  font-family: 'Droid Serif', serif;
`;
