import React, { useCallback, useMemo, useState } from 'react';
import { styled, media } from 'styles';
import { TextField, MenuItem } from '@material-ui/core';
import { usePurchase } from './hooks/usePurchase';
import CreditCardMask from 'modules/shared/components/inputs/CreditCardMask';
import {
  CpfMask,
  CreditCardValidateDateMask,
  PhoneMask,
} from 'modules/shared/components/inputs';
import { useToast } from 'modules/shared/hooks';
import { useCheckoutDialogTracker } from './hooks/useCheckoutDialogTracker';

export function Form({ selectedPricing, setStep, eventProps }) {
  const { setToastAfterReload } = useToast();
  const [installments, setInstallments] = useState(12);
  const [loading, setLoading] = useState(false);
  const { sendPayment, getCreditCardErrors, getUserDataErrors } = usePurchase();
  const { trackFailedPayment, trackSentPayment } = useCheckoutDialogTracker(
    eventProps
  );
  const [creditCardData, setCreditCardData] = useState(
    INITIAL_CREDIT_CARD_DATA
  );
  const [creditCardDataError, setCreditCardDataError] = useState(
    INITIAL_CREDIT_CARD_DATA
  );
  const [userData, setUserData] = useState(INITIAL_USER_DATA);
  const [userDataError, setUserDataError] = useState(INITIAL_USER_DATA);

  const onCreditCardInputChange = useCallback(
    (key, value) => {
      setCreditCardData({ ...creditCardData, [key]: value });
    },
    [setCreditCardData, creditCardData]
  );

  const onUserInputChange = useCallback(
    (key, value) => {
      setUserData({ ...userData, [key]: value });
    },
    [setUserData, userData]
  );

  const showInstallments = useMemo(() => {
    return selectedPricing && selectedPricing.plan.months > 1;
  }, [selectedPricing]);

  const installmentsOptions = useMemo(() => {
    if (!selectedPricing) return [];
    const options = [];

    for (let index = 0; index < selectedPricing.plan.months; index++) {
      const installments = index + 1;
      const fullPrice = selectedPricing.fullPrice;

      let text = '';
      if (installments === 1 && selectedPricing.plan.months === 12) {
        text = `1x de R̶$̶ ̶2̶9̶8̶,̶8̶0̶- R$ 268,20 (10% OFF à vista)`;
      } else {
        text = `${installments} x R$ ${(fullPrice / installments).toFixed(2)}${
          installments > 1 ? '  / mês' : ''
        }`;
      }

      options.push({ text, installments });
    }

    return options;
  }, [selectedPricing]);

  const submitPayment = useCallback(
    async e => {
      e.preventDefault();

      const creditCardErrors = getCreditCardErrors(creditCardData);
      setCreditCardDataError(creditCardErrors);

      const userErrors = getUserDataErrors(userData);
      setUserDataError(userErrors);

      const hasAnyCreditCardError =
        creditCardErrors.number ||
        creditCardErrors.holderName ||
        creditCardErrors.expiry ||
        creditCardErrors.cvv;

      const hasAnyUserError = userErrors.cpf || userErrors.phone;

      if (hasAnyCreditCardError || hasAnyUserError) return;

      setLoading(true);

      trackSentPayment();

      const { error } = await sendPayment(
        selectedPricing?.id,
        userData,
        creditCardData,
        installments
      );
      setLoading(false);

      if (error) {
        trackFailedPayment(error.message);
        try {
          setToastAfterReload(JSON.parse(error.message).join(','), 'error');
        } catch (_) {
          setToastAfterReload(error.message, 'error');
        }

        const currentUrl = window.location.href;
        window.location.href = currentUrl.split('/exercicios')[0];
        return;
      }

      setStep(3);
    },
    [
      creditCardData,
      getCreditCardErrors,
      getUserDataErrors,
      installments,
      selectedPricing,
      sendPayment,
      userData,
      trackSentPayment,
      trackFailedPayment,
      setStep,
      setToastAfterReload,
    ]
  );

  return (
    <FormContainer>
      <CustomTextField
        variant="standard"
        label="Número do Cartão"
        InputProps={{
          inputComponent: CreditCardMask,
        }}
        value={creditCardData.number}
        onChange={e => onCreditCardInputChange('number', e.target.value)}
        helperText={creditCardDataError.number}
        error={!!creditCardDataError.number}
      />
      <CustomTextField
        variant="standard"
        label="Nome no Cartão"
        value={creditCardData.holderName}
        onChange={e => {
          const newName = e.target.value;
          if (newName.length > 64) return;
          if (!newName.match(/^[a-zA-Z ]*$/)) return;

          onCreditCardInputChange('holderName', e.target.value);
        }}
        helperText={creditCardDataError.holderName}
        error={!!creditCardDataError.holderName}
      />
      <Inline>
        <CustomTextField
          variant="standard"
          label="Validade"
          InputProps={{
            inputComponent: CreditCardValidateDateMask,
          }}
          value={creditCardData.expiry}
          onChange={e => onCreditCardInputChange('expiry', e.target.value)}
          helperText={creditCardDataError.expiry}
          error={!!creditCardDataError.expiry}
          className="inline"
        />
        <CustomTextField
          variant="standard"
          label="CVV"
          value={creditCardData.cvv}
          onChange={e => {
            const newCvv = e.target.value;
            const newCvvOnlyNumbers = newCvv.replace(/[^\d.-]/g, '');
            if (newCvvOnlyNumbers.length > 4) return;

            onCreditCardInputChange('cvv', newCvvOnlyNumbers);
          }}
          helperText={creditCardDataError.cvv}
          error={!!creditCardDataError.cvv}
        />
      </Inline>

      <Inline>
        <CustomTextField
          variant="standard"
          label="CPF"
          InputProps={{
            inputComponent: CpfMask,
          }}
          value={userData.cpf}
          onChange={e => onUserInputChange('cpf', e.target.value)}
          helperText={userDataError.cpf}
          error={!!userDataError.cpf}
          className="inline"
        />
        <CustomTextField
          variant="standard"
          label="Telefone"
          InputProps={{
            inputComponent: PhoneMask,
          }}
          value={userData.phone}
          onChange={e => onUserInputChange('phone', e.target.value)}
          helperText={userDataError.phone}
          error={!!userDataError.phone}
        />
      </Inline>
      {showInstallments && (
        <>
          <CustomTextField
            variant="standard"
            label="Parcelas"
            value={installments}
            onChange={e => setInstallments(parseInt(e.target.value))}
            className="select"
            select
            style={{ textAlign: 'left' }}
          >
            {installmentsOptions.map(option => (
              <MenuItem key={option.installments} value={option.installments}>
                {option.text}
              </MenuItem>
            ))}
          </CustomTextField>
        </>
      )}
      <CustomButton onClick={e => submitPayment(e)} type="submit">
        {loading ? 'Comprando' : 'Finalizar compra'}
      </CustomButton>
    </FormContainer>
  );
}

const INITIAL_CREDIT_CARD_DATA = {
  number: '',
  holderName: '',
  expiry: '',
  cvv: '',
};

const INITIAL_USER_DATA = {
  cpf: '',
  phone: '',
};

const FormContainer = styled.form`
  width: 90%;
  padding: 0 22px;
  margin: 5px auto 0 auto;

  @media ${media.mobile} {
    width: 100%;
    padding: 0;
  }
`;

const CustomTextField = styled(TextField)`
  margin: 5px 0 !important;
  width: 100%;
  font-weight: bold;

  &.inline {
    margin-right: 10px !important;
  }

  &.select {
    .MuiInput-formControl {
      text-align: left;
    }
  }
`;

const Inline = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const CustomButton = styled.button`
  width: 90%;
  height: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #f2f2f2;
  font-size: 1.5rem;
  font-weight: bold;
  background-color: #f7ac3d;
  margin: 20px auto 0 auto;

  @media ${media.mobile} {
    width: 96%;
  }
`;
